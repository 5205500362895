import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import { contractTypes } from '../../../constants/contractTypes';
import { ppaTariffTypes } from '../../../constants/ppaTariffTypes';

const useClientContractOverlayValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				systemSize: yup
					.number()
					.typeError(t('System size must be a number'))
					.positive(t('System size must be positive'))
					.test(
						'decimal-places',
						'System size must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('System size is required'))
					.nullable(),
				mountingTypes: yup.array().min(1, t('Mounting type is required')).nullable(),
				epcPartner: yup
					.object()
					.required(t('EPC partner is required'))
					.shape({ label: yup.string(), value: yup.string() })
					.nullable(),
				epcVolume: yup
					.number()
					.typeError(t('EPC volume must be a number'))
					.positive(t('EPC volume must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('EPC volume is required'))
					.nullable(),
				currency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.test('required', t('Currency is required'), value => Boolean(value.value) || value.value === 0),
				yield: yup
					.number()
					.typeError(t('Yield must be a number'))
					.positive(t('Yield must be positive'))
					.min(1, t('Yield must be greater than 0'))
					.test(
						'decimal-places',
						t('Yield must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.typeError(t('Yield must be a number'))
					.required(t('Yield is required'))
					.nullable(),
				solarPvDegradation: yup
					.number()
					.typeError(t('Solar PV Degradation must be a number'))
					.min(0, t('Solar PV Degradation must be positive'))
					.max(100, t('Solar PV Degradation must be less than or equals to 100'))
					.required(t('Solar PV Degradation is required'))
					.test(
						'decimal-places',
						t('Yield must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					),
				annualOmEscalationRate: yup
					.number()
					.typeError(t('Annual O&M escalation rate must be a number'))
					.positive(t('Annual O&M escalation rate must be positive'))
					.min(0, t('Annual O&M escalation rate must be positive'))
					.max(100, t('Annual O&M escalation rate must be less than or equals to 100'))
					.required(t('Annual O&M escalation is required'))
					.test(
						'decimal-places',
						t('Annual O&M escalation rate must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					),
				extraCapexExpected: yup
					.number()
					.typeError(t('Extra CAPEX expected must be a number'))
					.positive(t('Extra CAPEX expected must be positive'))
					.min(0, t('Extra CAPEX expected must be positive'))
					.required(t('Extra CAPEX expected is required'))
					.test(
						'decimal-places',
						t('Extra CAPEX expected must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					),
				extraOpexExpected: yup
					.number()
					.typeError(t('Extra OPEX expected must be a number'))
					.positive(t('Extra OPEX expected must be positive'))
					.min(0, t('Extra OPEX expected must be positive'))
					.required(t('Extra OPEX expected is required'))
					.test(
						'decimal-places',
						t('Extra OPEX expected must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					),
				paymentGuaranteeType: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.required(t('Payment guarantee type is required'))
					.nullable(),
				paymentGuaranteeDetails: yup.string(),

				paymentGuaranteeAmount: yup
					.number()
					.typeError(t('Payment Guarantee Amount must be a number'))
					.positive(t('Payment Guarantee Amount must be positive'))
					.min(0, t('Payment Guarantee Amount must be positive'))
					.required(t('Payment Guarantee Amount is required'))
					.test(
						'decimal-places',
						t('Payment Guarantee Amount must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					),
				contractType: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.test('required', 'Contract Type is required', value => value && value.label && value.value)
					.nullable(),
				saasCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Saas Currency is required')),
				ppaTariffType: yup
					.object()
					.test('required', 'PPA Type is required', (value, { parent: { contractType } }) =>
						contractType?.label === contractTypes.PPA ? value && value.label && value.value : true,
					)
					.nullable(),
				monthlyLeaseRate: yup
					.number()
					.test('required', t('Monthly lease rate is required'), (value, { parent: { contractType } }) =>
						contractType?.label === contractTypes.Lease ? value : true,
					)
					.nullable(),
				ppaPricePerKwh: yup
					.number()
					.typeError(t('PPA tariff must be a number'))
					.positive(t('PPA tariff must be positive'))
					.min(0, t('PPA tariff must be positive'))
					.test(
						'decimal-places',
						'PPA tariff must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.test('required', t('PPA tariff is required'), (value, { parent: { contractType, ppaTariffType } }) =>
						contractType?.label === contractTypes.PPA && ppaTariffType?.label === ppaTariffTypes.FIXED
							? value
							: true,
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				annualOmPrice: yup
					.number()
					.typeError(t('Annual O&M Price must be a number'))
					.positive(t('Annual O&M Price must be positive'))
					.min(0, t('Annual O&M Price must be positive'))
					.required(t('Annual O&M Price is required'))
					.test(
						'decimal-places',
						'PPA tariff must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				clientUpfrontPayment: yup
					.number()
					.typeError(t('Client down-payment must be a number'))
					.positive(t('Client down-payment must be positive'))
					.min(0, t('Client down-payment must be positive'))
					.required(t('Client down-payment is required'))
					.test(
						'decimal-places',
						'Client down-payment must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				floorTariff: yup
					.number()
					.typeError(t('Floor tariff must be a number'))
					.positive(t('Floor tariff must be positive'))
					.min(0, t('Floor tariff must be positive'))
					.test('required', t('Floor tariff is required'), (value, { parent: { contractType, ppaTariffType } }) =>
						contractType?.label === contractTypes.PPA && ppaTariffType?.label === ppaTariffTypes.FLOATING
							? value
							: true,
					)
					.test(
						'decimal-places',
						'Floor tariff must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ceilingTariff: yup
					.number()
					.typeError(t('Ceiling tariff must be a number'))
					.positive(t('Ceiling tariff must be positive'))
					.min(0, t('Ceiling tariff must be positive'))
					.test(
						'required',
						t('Ceiling tariff is required'),
						(value, { parent: { contractType, ppaTariffType } }) =>
							contractType?.label === contractTypes.PPA && ppaTariffType?.label === ppaTariffTypes.FLOATING
								? value
								: true,
					)
					.test(
						'decimal-places',
						'Ceiling tariff must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				escalationRateInPercent: yup
					.number()
					.typeError(t('Escalation rate must be a number'))
					.min(0, t('Escalation rate must be more than or equals to 0'))
					.max(100, t('Escalation rate must be less than or equals to 100'))
					.test(
						'decimal-places',
						'Escalation rate must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Escalation rate is required'))
					.nullable(),
				contractDuration: yup
					.number()
					.typeError(t('Contract term must be a number'))
					.positive(t('Contract term must be positive'))
					.integer(t('Contract term must be a whole number'))
					.min(3, 'Contract term must be between 3 and 25')
					.max(25, 'Contract term must be between 3 and 25')
					.required(t('Contract term is required'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				solarUtilizationRate: yup
					.number()
					.typeError(t('Solar utilization rate must be a number'))
					.min(0, t('Solar utilization rate must be positive'))
					.max(100, t('Solar utilization rate must be less than or equals to 100'))
					.required(t('Solar utilization rate is required'))
					.test(
						'decimal-places',
						'Solar utilization rate must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				minimumPurchaseAmount: yup
					.number()
					.typeError(t('Minimum purchase amount must be a number'))
					.min(0, t('Minimum purchase amount must be more than or equals to 0'))
					.test('required', t('Minimum purchase amount is required'), (value, { parent: { contractType } }) =>
						contractType?.label === contractTypes.PPA ? value : true,
					)
					.test(
						'decimal-places',
						'Minimum purchase amount must have no more than 3 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				discountOnGridTariff: yup
					.number()
					.typeError(t('Discount on Grid Tariff rate must be a number'))
					.min(0, t('Discount on Grid Tariff must be positive'))
					.test('required', t('Discount on Grid Tariff is required'), (value, { parent: { contractType } }) =>
						contractType?.label === contractTypes.PPA ? value : true,
					)
					.test(
						'decimal-places',
						'Discount on Grid Tariff must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				billingPeriod: yup
					.number()
					.typeError(t('Billing period must be a number'))
					.positive(t('Billing period must be positive'))
					.integer(t('Billing period must be a whole number'))
					.max(12, t('Billing period must be less than or equal to 12'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Billing period is required')),
				contractParty: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.required(t('Signing entity is required'))
					.nullable(),
				saasVersion: yup
					.number()
					.typeError(t('SaaS Version rate must be a number'))
					.positive(t('SaaS Version must be positive'))
					.min(0, t('SaaS Version must be positive'))
					.test(
						'decimal-places',
						'SaaS Version rate must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('SaaS version is required'))
					.nullable(),
				longstop: yup.string().required(t('Longstop date is required')).nullable(),
				handoverPrice: yup
					.number()
					.typeError(t('Handover price must be a number'))
					.positive(t('Handover price must be positive'))
					.min(0, t('Handover price must be positive'))
					.test(
						'decimal-places',
						'Handover price must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Handover price is required'))
					.nullable(),
				bankAccount: yup
					.object()
					.when('contractParty', {
						is: contractParty => contractParty !== null,
						then: yup
							.object()
							.test(
								'required',
								t('Bank Account is required'),
								value => (value && Boolean(value?.value)) || value?.value === 0,
							)
							.nullable(),
					})
					.nullable(),
				easementFee: yup
					.number()
					.typeError(t('Easement fee must be a number'))
					.positive(t('Easement fee must be positive'))
					.min(0, t('Easement fee must be positive'))
					.test(
						'decimal-places',
						'Easement fee must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Easement fee is required'))
					.nullable(),
				minimumWarrantedFunctionality: yup
					.number()
					.typeError(t('Minimum Warranted Functionality must be a number'))
					.positive(t('Minimum Warranted Functionality be positive'))
					.min(0, t('Minimum Warranted Functionality be positive'))
					.max(100, t('Minimum Warranted Functionality must be less than or equals to 100'))
					.test(
						'decimal-places',
						'Minimum Warranted Functionality must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Minimum Warranted Functionality is required'))
					.nullable(),
				projectedIrr: yup
					.number()
					.typeError(t('Projected IRR must be a number'))
					.min(0, t('Projected IRR must be positive'))
					.max(100, t('Projected IRR must be less than or equals to 100'))
					.test(
						'decimal-places',
						'Projected IRR must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^-?\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Projected IRR is required'))
					.nullable(),
				worstCaseIrr: yup
					.number()
					.typeError(t('IRR at minimum Purchase Amount must be a number'))
					.positive(0, 'IRR at minimum Purchase Amount must be positive')
					.max(100, t('IRR at minimum Purchase Amount must be less than or equals to 100'))
					.test(
						'decimal-places',
						'IRR at minimum Purchase Amount must have no more than 3 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('IRR at minimum Purchase Amount is required'))
					.nullable(),
				saasSignatureDate: yup.string().required(t('SaaS signature date is required')).nullable(),
				contractNumber: yup.string().required(t('Contract number is required')).nullable(),
				minimumDscr: yup
					.number()
					.typeError(t('Minimum DSCR must be a number'))
					.positive(t('Minimum DSCR must be positive'))
					.test(
						'decimal-places',
						'Minimum DSCR must have no more than 2 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Minimum DSCR is required'))
					.nullable(),
				averageDscr: yup
					.number()
					.typeError(t('Average DSCR must be a number'))
					.positive(t('Average DSCR must be positive'))
					.test(
						'decimal-places',
						'Average DSCR must have no more than 2 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Average DSCR is required'))
					.nullable(),
			}),
		[t],
	);
};

export default useClientContractOverlayValidationSchema;
