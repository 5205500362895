import { INVOICE_STATUSES } from 'Invoice/constants/invoiceContstants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useInvoiceFormValidationSchema = isCreateMode => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				paymentDate: yup
					.date()
					.typeError(t('Payment date must be a date'))
					.nullable()
					.when('status', {
						is: status => status === INVOICE_STATUSES.PAID,
						then: yup.date().required(t('Payment date is required')),
					}),
				exchangeRate: yup
					.number()
					.typeError(t('Exchange rate must be a number'))
					.test(
						'decimal-places',
						'Exchange rate must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				invoiceTemplate: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.test('required', 'Invoice Template is required', value => isCreateMode || Boolean(value))
					.nullable(),
				bankAccount: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.test('required', 'Bank Account is required', value => isCreateMode || Boolean(value))
					.nullable(),
				countryVatPercentage: yup
					.number()
					.typeError(t('Country VAT must be a number'))
					.min(0, 'Country VAT mut not be less than 0')
					.max(100, 'Country VAT mut not be more than than 100')
					.test(
						'decimal-places',
						'Country VAT must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				countryWhtPercentage: yup
					.number()
					.typeError(t('Country WHT must be a number'))
					.min(0, 'Country WHT mut not be less than 0')
					.max(100, 'Country WHT mut not be more than than 100')
					.test(
						'decimal-places',
						'Country WHT must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				countryWhtVatPercentage: yup
					.number()
					.typeError(t('Country WHT VAT must be a number'))
					.min(0, 'Country WHT VAT mut not be less than 0')
					.max(100, 'Country WHT VAT mut not be more than than 100')
					.test(
						'decimal-places',
						'Country WHT VAT must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				countryNHILPercentage: yup
					.number()
					.typeError(t('Country NHIL must be a number'))
					.test(
						'decimal-places',
						'Country NHIL must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.min(0, 'Country NHIL mut not be less than 0')
					.max(100, 'Country NHIL mut not be more than than 100')
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				countryCovid19LevyPercentage: yup
					.number()
					.typeError(t('Country COVID-19 Levy must be a number'))
					.min(0, 'Country COVID-19 Levy mut not be less than 0')
					.max(100, 'Country COVID-19 Levy mut not be more than than 100')
					.test(
						'decimal-places',
						'Country COVID-19 Levy must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				countryGetFundLevyPercentage: yup
					.number()
					.typeError(t('Country GETFUND Levy must be a number'))
					.min(0, 'Country GETFUND Levy mut not be less than 0')
					.max(100, 'Country GETFUND Levy mut not be more than than 100')
					.test(
						'decimal-places',
						'Country GETFUND Levy must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				leasingFee: yup
					.number()
					.typeError(t('Leasing fee must be a number'))
					.test(
						'decimal-places',
						'Leasing fee must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ppaFee: yup
					.number()
					.typeError(t('PPA fee must be a number'))
					.test(
						'decimal-places',
						'PPA fee must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				maintenanceFee: yup
					.number()
					.typeError(t('Maintenance fee must be a number'))
					.test(
						'decimal-places',
						'Maintenance fee must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				subTotalExcludingTax: yup
					.number()
					.typeError(t('Sub total excluding tax fee must be a number'))
					.test(
						'decimal-places',
						'Sub total excluding tax fee must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				vat: yup
					.number()
					.typeError(t('VAT must be a number'))
					.test(
						'decimal-places',
						'VAT must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				subTotal: yup
					.number()
					.typeError(t('Sub total must be a number'))
					.test(
						'decimal-places',
						'Sub total must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				wht: yup
					.number()
					.typeError(t('WHT must be a number'))
					.test(
						'decimal-places',
						'WHT must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				whtVat: yup
					.number()
					.typeError(t('WHT VAT must be a number'))
					.test(
						'decimal-places',
						'WHT VAT must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				totalAmount: yup
					.number()
					.typeError(t('Total amount must be a number'))
					.test(
						'decimal-places',
						'Total amount must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ppaPricePerKwh: yup
					.number()
					.typeError(t('Price Per Kwh must be a number'))
					.test(
						'decimal-places',
						'Total amount must have no more than 5 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,5})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
			}),
		[isCreateMode, t],
	);
};

export default useInvoiceFormValidationSchema;
