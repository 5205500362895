import { Grid } from '@mui/material';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import CurrencySign from 'Common/components/CurrencySign';
import UNITS from 'Common/constants/units';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { useMemo } from 'react';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import { contractTypes } from '../../../constants/contractTypes';
import { ppaTarifOptions, PPA_TARIFS } from '../../../constants/ppaTarifs';
import loadClientContractTypeOptions from '../../../utils/loadClientContractTypeOptions';

const ComercialDetailsSection = ({
	mode,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
}) => {
	const { t } = useTranslation();
	const isViewMode = mode === crudModes.VIEW;

	const isLease = useMemo(
		() => values?.contractType?.label === contractTypes.Lease,
		[values?.contractType?.label],
	);
	const isPPA = useMemo(
		() => values?.contractType?.label === contractTypes.PPA,
		[values?.contractType?.label],
	);
	const isPpaTarifTypeFixed = useMemo(
		() => values?.ppaTariffType?.value === PPA_TARIFS.FIXED,
		[values?.ppaTariffType?.value],
	);
	const isPpaTarifTypeFloating = useMemo(
		() => values?.ppaTariffType?.value === PPA_TARIFS.FLOATING,
		[values?.ppaTariffType?.value],
	);

	return !isViewMode ? (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<FormField data-first-field>
						<FormikSelect
							isAsync
							id="contractType"
							name="contractType"
							label={t('Contract type')}
							value={values.contractType}
							error={errors.contractType}
							touched={touched.contractType}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							loadOptions={loadClientContractTypeOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="saasCurrency"
							name="saasCurrency"
							label={t('SaaS currency')}
							value={values.saasCurrency}
							error={errors.saasCurrency}
							touched={touched.saasCurrency}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isRequired
							isClearable
							isOverlay
						/>
					</FormField>
					<ConditionalGridSection hasFlag={isLease}>
						<FormField>
							<FormikInput
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								id="monthlyLeaseRate"
								name="monthlyLeaseRate"
								label={t('Lease fee')}
								value={values.monthlyLeaseRate}
								error={errors.monthlyLeaseRate}
								touched={touched.monthlyLeaseRate}
								onChange={handleChange}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={isPPA}>
						<FormField>
							<FormikSelect
								id="ppaTariffType"
								name="ppaTariffType"
								label={t('PPA tariff type')}
								value={values.ppaTariffType}
								error={errors.ppaTariffType}
								touched={touched.ppaTariffType}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								options={ppaTarifOptions}
								isRequired
								isClearable
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={isPPA && isPpaTarifTypeFixed}>
						<FormField>
							<FormikInput
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								id="ppaPricePerKwh"
								name="ppaPricePerKwh"
								label={t('PPA tariff')}
								value={values.ppaPricePerKwh}
								error={errors.ppaPricePerKwh}
								touched={touched.ppaPricePerKwh}
								onChange={handleChange}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={isPPA && isPpaTarifTypeFloating}>
						<FormField>
							<FormikInput
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								id="floorTariff"
								name="floorTariff"
								label={t('Floor tariff')}
								value={values.floorTariff}
								error={errors.floorTariff}
								touched={touched.floorTariff}
								onChange={handleChange}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
						<FormField>
							<FormikInput
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								id="ceilingTariff"
								name="ceilingTariff"
								label={t('Ceiling tariff')}
								value={values.ceilingTariff}
								error={errors.ceilingTariff}
								touched={touched.ceilingTariff}
								onChange={handleChange}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<FormField>
						<FormikInput
							unit={UNITS.PERCENT}
							label={t('Escalation rate')}
							id="escalationRateInPercent"
							name="escalationRateInPercent"
							error={errors.escalationRateInPercent}
							touched={touched.escalationRateInPercent}
							value={values.escalationRateInPercent}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							label={t('Contract term')}
							id="contractDuration"
							name="contractDuration"
							error={errors.contractDuration}
							touched={touched.contractDuration}
							value={values.contractDuration}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							prefix={() => <CurrencySign currency={values.saasCurrency} />}
							label={t('Client down-payment')}
							id="clientUpfrontPayment"
							name="clientUpfrontPayment"
							error={errors.clientUpfrontPayment}
							touched={touched.clientUpfrontPayment}
							value={values.clientUpfrontPayment}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<ConditionalGridSection hasFlag={isPPA}>
						<FormField>
							<FormikInput
								label={t('Minimum purchase amount')}
								id="minimumPurchaseAmount"
								name="minimumPurchaseAmount"
								error={errors.minimumPurchaseAmount}
								touched={touched.minimumPurchaseAmount}
								value={isPPA ? values.minimumPurchaseAmount : '100'}
								onChange={handleChange}
								onBlur={handleBlur}
								unit={UNITS.PERCENT}
								isDisabled={!isPPA}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={isPPA}>
						<FormField {...(isPPA ? { 'data-last-field': true } : {})}>
							<FormikInput
								id="discountOnGridTariff"
								name="discountOnGridTariff"
								label={t('Discount on Grid Tariff')}
								value={values.discountOnGridTariff}
								error={errors.discountOnGridTariff}
								touched={touched.discountOnGridTariff}
								onChange={handleChange}
								onBlur={handleBlur}
								unit={UNITS.PERCENT}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<FormikInput
						label={t('Billing period')}
						id="billingPeriod"
						name="billingPeriod"
						error={errors.billingPeriod}
						touched={touched.billingPeriod}
						value={values.billingPeriod}
						onChange={handleChange}
						onBlur={handleBlur}
						tooltip={t("How often in months are the invoices issues (for example '1' means every month)")}
						isOverlay
						isRequired
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="contractType" title={t('Contract type')} value={values.contractType?.label} />
					<KeyValueVisualization id="saasCurrency" title={t('SaaS currency')} value={values.saasCurrency?.label} />
					{isLease && (
						<KeyValueVisualization
							id="saasCurrency"
							prefix={() => <CurrencySign currency={values.saasCurrency} />}
							title={t('Lease fee')}
							value={values.monthlyLeaseRate}
						/>
					)}
					{isPPA && (
						<KeyValueVisualization
							id="ppaTariffType"
							title={t('PPA tariff type')}
							value={values.ppaTariffType?.label}
						/>
					)}
					{isPPA && isPpaTarifTypeFixed && (
						<KeyValueVisualization id="ppaPricePerKwh" title={t('PPA tariff')} value={values.ppaPricePerKwh} />
					)}
					{isPPA && isPpaTarifTypeFloating && (
						<>
							<KeyValueVisualization
								id="saasCurrency"
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								title={t('Floor tariff')}
								value={values.floorTariff}
							/>
							<KeyValueVisualization
								id="saasCurrency"
								prefix={() => <CurrencySign currency={values.saasCurrency} />}
								title={t('Ceiling tariff')}
								value={values.ceilingTariff}
							/>
						</>
					)}
					<KeyValueVisualization
						id="escalationRateInPercent"
						title={t('Escalation rate')}
						value={values.escalationRateInPercent}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="contractDuration"
						title={t('Contract term')}
						value={values.contractDuration}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="clientUpfrontPayment"
						prefix={() => <CurrencySign currency={values.saasCurrency} />}
						title={t('Client down-payment')}
						value={values.clientUpfrontPayment}
					/>
					{isPPA && (
						<KeyValueVisualization
							id="minimumPurchaseAmount"
							title={t('Minimum purchase amount')}
							value={values.minimumPurchaseAmount}
							unit={UNITS.PERCENT}
						/>
					)}
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

ComercialDetailsSection.defaultProps = {
	mode: crudModes.CREATE,
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
};

ComercialDetailsSection.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT, crudModes.VIEW]),
	values: PropTypes.shape({
		contractType: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		saasCurrency: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		monthlyLeaseRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		ppaTariffType: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		ppaPricePerKwh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		floorTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		ceilingTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		escalationRateInPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		contractDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		clientUpfrontPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		minimumPurchaseAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		discountOnGridTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		billingPeriod: PropTypes.string,
	}),
	errors: PropTypes.shape({
		contractType: PropTypes.string,
		saasCurrency: PropTypes.string,
		monthlyLeaseRate: PropTypes.string,
		ppaTariffType: PropTypes.string,
		ppaPricePerKwh: PropTypes.string,
		floorTariff: PropTypes.string,
		ceilingTariff: PropTypes.string,
		escalationRateInPercent: PropTypes.string,
		contractDuration: PropTypes.string,
		clientUpfrontPayment: PropTypes.string,
		minimumPurchaseAmount: PropTypes.string,
		discountOnGridTariff: PropTypes.string,
		billingPeriod: PropTypes.string,
	}),
	touched: PropTypes.shape({
		contractType: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		saasCurrency: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		monthlyLeaseRate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		ppaTariffType: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		ppaPricePerKwh: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		floorTariff: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		ceilingTariff: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		escalationRateInPercent: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		contractDuration: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		clientUpfrontPayment: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		minimumPurchaseAmount: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		discountOnGridTariff: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		billingPeriod: PropTypes.bool,
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
};

export default ComercialDetailsSection;
